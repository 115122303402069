import cn from 'classnames';
import Image, { ImageProps } from 'next/image';
import { FC } from 'react';

import { Attachment } from '~types';

import s from './ShadowImage.module.css';

export interface ShadowImageProps extends Partial<ImageProps> {
  data: Partial<Attachment>;
  version?: 'thumbnail' | 'url';
  className?: string;
}

export const getLinearGradient = (colors: string) => `linear-gradient(to bottom, ${colors})`;

const ShadowImage: FC<ShadowImageProps> = ({
  data,
  className,
  version = 'url',
  alt,
  ...imageProps
}) => {
  const defaultProps: ImageProps = {
    src: data?.[version] || '',
    fill: true,
    className: s.img,
    alt: '',
  };
  const { dominantColors } = data;
  return (
    <div
      className={cn(s.root, className)}
      style={dominantColors ? { backgroundImage: getLinearGradient(dominantColors) } : {}}
    >
      <Image {...defaultProps} {...imageProps} alt={alt || ' '} />
    </div>
  );
};

export default ShadowImage;
