import classNames from 'classnames';
import Head from 'next/head';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';

import Carousel from '~components/ui/Carousel';
import { getLinearGradient } from '~components/ui/ShadowImage';
import getNextImageSrc from '~utils/getNextImageSrc';
import go from '~utils/go';
import isTouchScreen from '~utils/isTouchScreen';

import { Banner } from '~types';

import s from './Banner.module.css';

interface Props {
  data: Banner[];
}

const Banners: FC<Props> = ({ data }) => {
  const [isTouch, setIsTouch] = useState(false);
  const [index, setIndex] = useState(1);
  const [renderImages, setRenderImages] = useState(false);

  useEffect(() => {
    setIsTouch(isTouchScreen());
  }, []);

  const handleMove = (newIndex: number) => {
    setRenderImages(true);
    setIndex(newIndex);
  };

  const renderIMG = (banner: Banner) => {
    const src = banner.image.url;
    const xl = getNextImageSrc({ src, width: 1920 });
    const lg = getNextImageSrc({ src, width: 1200 });
    const md = getNextImageSrc({ src, width: 750 });
    const sm = getNextImageSrc({ src, width: 640 });

    return (
      <picture className={s.picture}>
        <source srcSet={xl} media="(min-width: 1280px)" />
        <source srcSet={lg} media="(min-width: 768px) and (max-width: 1280px)" />
        <source srcSet={md} media="(min-width: 376px) and (max-width: 768px)" />
        <img alt={banner.title} src={sm} className={s.image} />
      </picture>
    );
  };
  const [priorityImage] = data;
  return (
    <div
      className={s.root}
      onTouchStart={() => setRenderImages(true)}
      onMouseEnter={() => setRenderImages(true)}
    >
      <Head>
        <link
          rel="preload"
          as="image"
          imageSrcSet={getNextImageSrc({ src: priorityImage.image.url, width: 640 })}
          imageSizes="100vw"
          fetchPriority="high"
        />
      </Head>
      {data.length > 1 ? (
        <Carousel
          infinityLoop
          auto
          duration={5000}
          fade={!isTouch}
          onMove={handleMove}
          currentIndex={index}
          onClickItem={(itemIndex) => {
            const banner = data[itemIndex - 1];
            if (banner?.link) go(banner.link);
          }}
        >
          {data.map((banner, index) =>
            banner.link ? (
              <div key={banner.id} className={s.banner}>
                <Link
                  href={banner.link}
                  className="relative block w-full h-full"
                  prefetch={false}
                  style={
                    banner?.image?.dominantColors
                      ? { backgroundImage: getLinearGradient(banner?.image?.dominantColors) }
                      : {}
                  }
                >
                  {!index || renderImages ? renderIMG(banner) : null}
                </Link>
              </div>
            ) : (
              <div key={banner.id} className={s.banner}>
                <div
                  className="relative w-full h-full"
                  style={
                    banner?.image?.dominantColors
                      ? { backgroundImage: getLinearGradient(banner?.image?.dominantColors) }
                      : {}
                  }
                >
                  {renderIMG(banner)}
                </div>
              </div>
            ),
          )}
        </Carousel>
      ) : (
        data.map((banner) =>
          banner.link ? (
            <div
              key={banner.id}
              className={s.banner}
              style={
                banner?.image?.dominantColors
                  ? { backgroundImage: getLinearGradient(banner?.image?.dominantColors) }
                  : {}
              }
            >
              <Link href={banner.link} className="relative block w-full h-full" prefetch={false}>
                {renderIMG(banner)}
              </Link>
            </div>
          ) : (
            <div
              key={banner.id}
              className={s.banner}
              style={
                banner?.image?.dominantColors
                  ? { backgroundImage: getLinearGradient(banner?.image?.dominantColors) }
                  : {}
              }
            >
              <div className="relative w-full h-full">{renderIMG(banner)}</div>
            </div>
          ),
        )
      )}
      <div className="bannerGradient" />
      <div className={s.dots}>
        {data.map((item, i) => (
          <span
            className={classNames(s.dot, { [s.selectedDot]: i + 1 === index })}
            key={item.id}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleMove(i + 1);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Banners;
